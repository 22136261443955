import { getSession, signIn } from 'next-auth/react';

export const postSetPassword = async (formData: Record<string, unknown>) => {
  try {
    const session = await getSession();
    const response = await fetch(`/api/set-password`, {
      method: 'POST',
      body: JSON.stringify({
        password: formData.password,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    await signIn('credentials', {
      redirect: false,
      email: session?.user.email,
      password: formData.password,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
