'use client';

import { useCurrentLocale, useI18n } from '~/locales/client';
import { AndercoreExternalLinks } from '~/constants/endpoints';
import Link from 'next/link';
import Card from '~/components/customComponents/cards/Card';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import Button from '~/components/customComponents/buttons/Button';
import {
  AcceptedCookiePolicyEnum,
  AcceptedCookiePolicyType,
} from '~/types-and-enums/cookieTypes';

type CookieCardProps = {
  onOpen: () => void;
  acceptedCookiePolicy: AcceptedCookiePolicyType;
  setAcceptedCookiePolicy: (policy: AcceptedCookiePolicyEnum) => void;
};

export default function CookieCard({
  onOpen,
  acceptedCookiePolicy,
  setAcceptedCookiePolicy,
}: CookieCardProps) {
  const t = useI18n();
  const locale = useCurrentLocale();

  if (acceptedCookiePolicy) return null;

  return (
    <Card className="fixed bottom-30 md:bottom-10 left-1/2 transform -translate-x-1/2 w-11/12 sm:max-w-96 md:max-w-192 bg-white shadow-xl p-4">
      <Flex className="justify-between flex-col md:flex-row items-end">
        <Flex className="flex-2 p-2 flex-col">
          <Text className="mb-4 text-xl font-bold">
            {t('cookieBanner.toast.title')}
          </Text>
          <Text className="text-base">{t('cookieBanner.toast.text')}</Text>
          <Flex className="mt-2">
            <Link
              className="underline hover:text-secondary"
              href={AndercoreExternalLinks?.[locale]?.privacy ?? ''}
            >
              {t('cookieBanner.toast.privacy-policy')}
            </Link>
          </Flex>
        </Flex>
        <Flex className="items-center p-2 h-fit w-full md:w-1/3 gap-4 flex-col self-center">
          <Button
            onClick={onOpen}
            buttonClassName="border border-gray-300 w-auto bg-white w-full hover:bg-gray-50"
            textClassName="text-gray-900"
            text={t('cookieBanner.toast.button.customise')}
          />
          <Button
            onClick={() =>
              setAcceptedCookiePolicy(AcceptedCookiePolicyEnum.All)
            }
            text={t('cookieBanner.toast.button.accept')}
            buttonClassName="w-full hover:bg-red-600"
          />
        </Flex>
      </Flex>
    </Card>
  );
}
