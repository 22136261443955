import {
  AcceptedCookiePolicyEnum,
  AcceptedCookiePolicyType,
  CookieSettingType,
  CookieTypeEnum,
} from '~/types-and-enums/cookieTypes';

export const getCookieSettings = (): CookieSettingType[] => [
  {
    title: 'cookieBanner.modal.functional.title',
    description: 'cookieBanner.modal.functional.description',
    type: CookieTypeEnum.Functional,
    required: true,
  },
  {
    title: 'cookieBanner.modal.analytics.title',
    description: 'cookieBanner.modal.analytics.description',
    type: CookieTypeEnum.Analytics,
  },
  {
    title: 'cookieBanner.modal.behavioral.title',
    description: 'cookieBanner.modal.behavioral.description',
    type: CookieTypeEnum.Behavioral,
  },
  {
    title: 'cookieBanner.modal.errorTracking.title',
    description: 'cookieBanner.modal.errorTracking.description',
    type: CookieTypeEnum.ErrorTracking,
  },
];

export const getInitiallySelectedCookies = (
  acceptedCookiePolicy: AcceptedCookiePolicyType
) => {
  if (
    acceptedCookiePolicy === AcceptedCookiePolicyEnum.All ||
    !acceptedCookiePolicy
  ) {
    return getCookieSettings().map((setting) => setting.type);
  }

  return acceptedCookiePolicy.split('') as CookieTypeEnum[];
};
