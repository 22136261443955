import Link from 'next/link';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import Flex from '../flex/Flex';

type CardProps = {
  children: React.ReactNode | string;
  className?: string;
  shadow?: boolean;
  href?: string;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Card component that displays content in a card-like container.
 * - Can be used as a link by passing the href prop.
 * @param children - The content to be displayed in the card.
 * @param className - Additional classes to be added to the card.
 * @param shadow - Boolean indicating if the card should have a shadow.
 * @param href - The URL to navigate to when the card is clicked.
 * @param props - Additional props to be added to the card.
 * @returns A card component.
 */
const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, className, shadow, href, ...props }, ref) => {
    const classes = twMerge(
      'flex flex-col py-6 px-4 gap-6 bg-white border border-solid border-gray-100 rounded-2xl w-full',
      shadow && 'hover:shadow-md transition-shadow duration-300 ease-in-out',
      className
    );
    if (href) {
      return (
        <Link href={href} className={classes} data-testid="href-card">
          {children}
        </Link>
      );
    }

    return (
      <Flex className={classes} data-testid="card" ref={ref} {...props}>
        {children}
      </Flex>
    );
  }
);

Card.displayName = 'Card';
export default Card;
