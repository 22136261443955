'use client';

import { useEffect, useRef } from 'react';
import { Icon, IconName } from '~/components/core/Icon';
import { spacing } from '~/utils/tailwindUtils';
import Card from '../cards/Card';
import Flex from '../flex/Flex';
import { twMerge } from 'tailwind-merge';

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  outsideClick?: boolean;
  size?: 'sm' | 'lg';
};

/**
 * Provides a modal component that can be used to display content in a modal window with a close button.
 * The modal will close if the user clicks outside of it.
 * @param isOpen - boolean to determine if the modal is open
 * @param closeModal - function to close the modal
 * @param children - content to display in the modal
 * @returns Modal component
 */
const Modal = ({
  isOpen,
  closeModal,
  children,
  outsideClick,
  size = 'lg', // TODO: Fix, find a better way: https://github.com/andercore-labs/buyer-app-ui/pull/615#discussion_r1708801903
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  // Close the modal if clicking outside of the modal
  useEffect(() => {
    if (outsideClick) {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          modalRef.current &&
          !modalRef.current.contains(event.target as Node)
        ) {
          closeModal();
        }
      };

      if (isOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
      }

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
  }, [isOpen, closeModal, outsideClick]);

  if (!isOpen) return null;

  return (
    <Flex className="fixed inset-0 items-center justify-center bg-gray-900 bg-opacity-50 z-100">
      <Card
        className={twMerge(
          'bg-white rounded-lg w-11/12 sm:w-auto relative p-6 md:p-10',
          size === 'sm' && 'lg:p-10',
          size === 'lg' && 'lg:p-20'
        )}
        ref={modalRef}
      >
        <button
          className="absolute top-3 right-3 md:top-6 md:right-6 md:pt-1 md:pl-1 text-gray-600 hover:bg-gray-50 rounded-full"
          onClick={closeModal}
        >
          <Icon name={IconName.Cancel} size={spacing[6]} colorMode="fill" />
        </button>
        <Flex className="flex-col items-center gap-4 pt-4">{children}</Flex>
      </Card>
    </Flex>
  );
};

export default Modal;
